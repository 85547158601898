<template lang="pug">
  article.playlist.fixed.z-10.overlay.flex.flex-col.px-8.lg_px-20.text-navy.font-messina.leading-normal(v-if="doc")
    //- slices...
    .absolute.overlay.px-16.flex.items-center.justify-center.transition-opacity.duration-700.select-none(v-for="(slice, i) in doc.data.body", :key="'lbl'+i", :class="{'opacity-0 pointer-events-none': current !== i}")
      //- label
      figure.w-full.max-w-record.rounded-full.overflow-hidden.relative.cursor-pointer(:class="{'record-label--rotate': true}", :style="{background: slice.primary.record_label_bg_color, 'animation-play-state': !paused && current === i ? 'running' : 'paused'}", @click="togglePlayback(i)")
        //- square shape
        .w-full.pb-ar-1x1
        //- (image)
        resp-img.transition-opacity.duration-700.delay-1500(:bg="true", :image="slice.primary.record_label_image", v-if="slice.primary.record_label_image.url", :class="{'opacity-0': i !== current}")
        //- curved text
        <svg class="text-xl absolute z-20 overlay block transform rotate-60" viewBox="0 0 333 333" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio style="backface-visibility: hidden">
          //- <circle cx="166.5" cy="166.5" r="108" stroke="black"/>
          //- <rect x="0.5" y="0.5" width="332" height="332" stroke="black"/>
          <path id="curve" d="M58.5,166.5a108,108 0 1,1 216,0a108,108 0 1,1 -216,0"/>
          <text font-size="1em">
            <textPath xlink:href="#curve">{{ slice.primary.record_label_text }}</textPath>
          </text>
        </svg>

    //- "hole" of record label
    .absolute.z-10.overlay.flex.items-center.justify-center.pointer-events-none
      .h-10.w-10.bg-vanilla.relative.z-10.rounded-full

    //- tracklist
    .relative.z-10.flex-1.w-full.flex.flex-col.justify-end.lg_pointer-events-none.transition.duration-300.transform.lg_transform-none(:class="{'translate-y-full lg_opacity-0 pointer-events-none': !tracklist}", @click="tracklist = false")
      ul.bg-vanilla.lg_bg-transparent.py-10.lg_py-0
        li(v-for="(slice, i) in doc.data.body")
          button.flex.text-left.pt-2.lg_pt-0(:class="{'pointer-events-auto': tracklist}", @click.stop="onListItemClick(i)")
            span.inline-block.text-left(style="min-width:1.25em") {{ i + 1 }}
            | {{ $prismic.asText(slice.primary.title1) }}

    //- controls
    footer-links.relative.z-10.lg_flex.justify-between.bg-vanilla.lg_pt-20
      nav.flex.w-full.lg_w-1x3.xl_w-1x4.justify-between.lg_w-auto.lg_justify-start
        //- tracklist btn
        button.lg_mr-24.text-left(@click="tracklist = !tracklist", style="min-width:4em") {{ tracklist ? 'Hide' : 'Tracklist' }}
        //- play/pause
        button.lg_mr-24(@click="togglePlayback(current)") {{ paused ? 'Play' : 'Pause' }}
        //- time/duration
        div.lg_mr-24 {{ paused && duration !== Infinity ? hhmmss(duration) : hhmmss(time) }}
        //- (menu for mbl spcacing)
        div.invisible.lg_hidden Menu

      //- captions
      .hidden.lg_block.lg_w-1x3.xl_flex-1.px-8
        rich-text.text-center.truncate(v-for="(slice, i) in doc.data.body", :field="slice.primary.caption", v-show="current === i", :key="i")
      //- spacer to center captions
      .lg_w-1x3.xl_w-1x4

    //- audios
    .fixed.top-0.right-0(v-show="$route.hash === '#debug'")
      //- wrapper so $refs[i] is not null...
      div(v-for="(slice, i) in doc.data.body", ref="audio")
        audio(v-if="slice.primary.audio_filename && loadAudio.includes(i)", :src="'/audio/' + slice.primary.audio_filename", @timeupdate="$event => onTimeupdate($event, i)", @loadedmetadata="$event => onAudioLoaded($event, i)", @pause="$event => onPaused($event, i)", controls, @playing="onPlaying", @ended="nextTrack", @error="onError", @stalled="onError")
    //- errors
    .fixed.top-0.left-0.pt-6.px-8.lg_px-20.pointer-events-none(v-if="error") {{error}}
    //- ul.fixed.top-0.left-0.pt-6
      li(v-for="line in log") {{line}}
</template>

<script>
export default {
  name: 'Playlist',
  computed: {
    doc () {
      return this.$store.state.docs.find(doc => doc.uid === this.$route.params.playlist)
    }
  },
  data () {
    return {
      current: 0,
      tracklist: this.$store.getters.is('lg'),
      paused: true,
      time: 0,
      duration: 0,
      canAnim: true,
      error: null,
      errorTmOut: null,
      loadAudio: [0]
      // log: []
    }
  },
  methods: {
    audioEl (i) {
      return this.$refs.audio[i]?.querySelector('audio')
    },
    hhmmss (seconds) {
      seconds = (!seconds || seconds === Infinity) ? 0 : seconds
      return new Date(seconds * 1000).toISOString().substr(11, 8).replace(/^00:/gm, '')
    },
    togglePlayback (i) {
      return this.paused ? this.play(i) : this.pause(i)
    },
    async play (i, fromBeginning) {
      try {
        i = i ?? this.current
        const audio = this.audioEl(i)
        if (!audio) return console.warn('Audio not loaded yet')
        if (audio && audio.paused) {
          await audio.play()
        }
      } catch (e) {
        console.error('Audio play error', e)
      }
    },
    async pause (i) {
      try {
        i = i ?? this.current
        const audio = this.audioEl(i)
        if (audio && !audio.paused) {
          await audio.pause()
          // this.paused = true
        }
      } catch (e) {
        console.error('Audio pause error', e)
      }
    },
    onPaused (e, i) {
      if (this.current === i) {
        this.paused = true
      }
      // console.log('@paused, paused:', this.paused)
    },
    onPlaying () {
      this.paused = false
      this.clearErrors()
      // console.log('@playing, paused:', this.paused)
    },
    nextTrack () {
      if (this.current < this.doc.data.body.length - 1) {
        this.current++
      }
    },
    onListItemClick (i) {
      if (this.current === i) {
        this.play(i)
      } else {
        this.current = i
      }
    },
    onAudioLoaded (e, i) {
      // if current...
      if (this.current === i) {
        // update duration
        // this.duration = e.target.duration
        this.setDuration(e.target)
        // play if loading new track (not page init)
        if (this.loadAudio.length > 1) {
          this.play(i)
        }
      }
    },
    onTimeupdate (e, i) {
      if (this.current === i) {
        this.time = e.target.currentTime

        // correct duration if incorrect (safari)
        // if (!this.duration || this.duration === Infinity) {
        //   this.duration = e.target.duration
        //   console.log('fix duration', this.duration, e.target.duration)
        // }
      }
      this.clearErrors() // because safari doesn't clear on @playing...
    },
    setDuration (el) {
      if (el.duration && el.duration !== Infinity) {
        this.duration = el.duration
      } else if (el) {
        const url = el.getAttribute('src')
        getDuration(url, duration => {
          this.duration = duration
        })
      }
    },

    // error handling
    onError (e) {
      this.setError(e, 'Audio ' + (e.type || 'Error'))
      // TODO - maybe ignore stalled if loading first track since safari always stalls at end of load...
    },
    onStalled (e) {
      this.setError(e, 'Audio stalled')
    },
    setError (e, msg = 'Audio Error', delay = 3000) {
      console.error(msg, e)
      this.errorTmOut = setTimeout(() => { this.error = msg }, delay)
    },
    clearErrors () {
      clearTimeout(this.errorTmOut)
      this.error = null
    }
  },
  created () {
    this.$store.commit('setUIColor', 'navy')
  },
  watch: {
    current (next, prev) {
      // pause prev
      this.pause(prev)
      // update time for cosmetic...
      this.time = 0
      // if next loaded...
      const audio = this.audioEl(next)
      if (audio) {
        // begin next
        this.setDuration(audio)
        audio.currentTime = 0
        audio.play()
      } else {
        // ...else load track, it will autoplay if current
        this.loadAudio.push(next)
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('setUIColor')
    next()
  }
}

// get duration since safari always Infinity
const getDuration = function (url, next) {
  const _player = new Audio(url)
  _player.addEventListener('durationchange', function (e) {
    if (this.duration !== Infinity) {
      var duration = this.duration
      _player.remove()
      next(duration)
      console.log('fixed duration')
    }
  }, false)
  _player.load()
  _player.currentTime = 24 * 60 * 60 // fake big time
  // _player.volume = 0;
  // _player.play();
}
</script>

<style>
.playlist{
  & text{
    fill: var(--vanilla);
    font-size:20px;
  }
  & figure{
    /*animation: spin 12s linear infinite;*/
    &.record-label--rotate{
      animation: spin 12s linear infinite;
    }
  }
}

@keyframes{
  0%{
    transform: rotate(0deg);
  }
  100%{
    transition: rotate(360deg);
  }
}
</style>
